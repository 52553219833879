import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom'
import ScrollToTop from "react-scroll-to-top";
import Landing from '../../Landing/Landing';

const Footer = () => {
  // const navigate = useNavigate();
  function clickSub() {
    return <Landing name={"Hiii"} />
  }
  const { t } = useTranslation();
  //   const scrollhandle = (event) =>{
  // event.preventDefault()
  // navigate(`/landing#demo`);


  //   }
  return (
    <>
      <div className='footer-section'>
        <ScrollToTop smooth className="scroll-btn" top="250" color="#fff" />
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-4'>
              <div className='footer-left'>
                {/* <div className='footer-image' >
                  <img className='footer-logo' src='/images/footer-qr.png' alt='no-data' />
                </div>
                <p className='footer-para'>{t('footer.thiswbsite')} <br/> {t('footer.around')} 0.33g of CO2e</p> */}

              
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0 footer-image">
                    <img className='office-image' src="/images/office-image.webp" alt="..." />
                  </div>
                  <div className="flex-grow-1 ms-3 footer-para">
                  <h5 className="office-header mt-0">Finland Office</h5>
                  Otakaari 5, 02150 Espoo <br/>Finland
                  </div>
                </div>
                {/* <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0 footer-image">
                    <img className='office-image' src="/images/cst-office.png" alt="..." />
                  </div>
                  <div className="flex-grow-1 ms-3 footer-para">
                  <h5 className="office-header mt-0">India Office</h5>
                  3rd floor, Gowri Arcade, HIG-A-85, <br/> Jayabheri Enclave, Gachibowli, Hyderabad, Telangana 500032, India
                  </div>
                </div> */}
                {/* <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 footer-image">
                    <img className='office-image' src="/images/dlt-labs-office.png" alt="..." />
                  </div>
                  <div className="flex-grow-1 ms-3 footer-para">
                    <h5 className="office-header mt-0">UAE Office</h5>
                    Tamani Arts 1519, Business Bay,<br/>Dubai
                  </div>
                </div> */}
              </div>
            </div>
            <div className='col-md-5'>
              <div className='row justify-content-center'>
                <div className='col-md-6 col-4'>
                  <ul className='footer-menus'>
                    <li className='footer-menu-inside'><NavLink className="inside-menu" to={"/sustainable-learning-hub"}>{t('footer.Learn')}</NavLink></li>
                    <li className='footer-menu-inside'><NavLink className="inside-menu" to={"/challenges"}>{t('footer.Challenges')}</NavLink></li>
                    <li className='footer-menu-inside'><NavLink to={"/marketplace#redeemcredits"} className="inside-menu">{t('footer.redeemcredits')}</NavLink></li>
                    <li className='footer-menu-inside'><NavLink className="inside-menu" to={"/about-us#aboutdata"}>{t('footer.aboutus')}</NavLink></li>
                  </ul>

                </div>
                <div className='col-md-6 col-4'>
                  <ul className='footer-menus'>
                    <li className='footer-menu-inside'><NavLink to={"/about-us#mission&vision"} className="inside-menu">{t('footer.missionvision')}</NavLink></li>
                    <li className='footer-menu-inside'><NavLink to="/contactus" className="inside-menu">{t('footer.contact')}</NavLink></li>
                    <li className='footer-menu-inside'><NavLink to="/privacypolicy" className="inside-menu">{t('footer.Privacy')}</NavLink></li>
                    <li className='footer-menu-inside'><NavLink to="/termsandconditions" className="inside-menu">{t('footer.terms')}</NavLink></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className='col-md-3'>
              <div className='footer-right'>
                <ul className='footer-right-images'>
                  <li>
                    {/* <NavLink className="image-all-data">
                    <div className='footerqr-image-data'>
                      <img className='qr-code' src='../images/landing/app-qr.png' alt='no-data'/>
                    </div>
                    
                    </NavLink> */}
                    <div className="social-media-icons">
                      <ul className="footer-social">
                        <li className="group-icon">
                          <NavLink
                            className="inside-group"
                            to="https://www.instagram.com/be.cerohero"
                            target="_blank"
                            aria-label="instagram social media"
                          >
                            <i className="fab fa-instagram icon-data" />
                          </NavLink>
                        </li>
                        <li className="group-icon">
                          <NavLink
                            className="inside-group"
                            to="https://www.linkedin.com/company/cerohero"
                            target="_blank"
                            aria-label="linked in social media"
                          >
                            <i className="fab fa-linkedin-in icon-data" />
                          </NavLink>
                        </li>
                        <li className="group-icon">
                          <NavLink
                            className="inside-group"
                            to="https://t.me/cerohero"
                            target="_blank"
                            aria-label="telegram social media"
                          >
                            <i className="fab fa-telegram icon-data"></i>
                          </NavLink>
                        </li>
                        {/* <li className="group-icon">
                                <NavLink
                                    className="inside-group"
                                    to="https://www.youtube.com/@be.cerohero"
                                    target="_blank"
                                >
                                    <i className="fab fa-youtube icon-data" />
                                </NavLink>
                            </li> */}
                        <li className="group-icon">
                          <NavLink
                            className="inside-group"
                            to="https://twitter.com/be_cerohero"
                            target="_blank"
                            aria-label="x social media"
                          >
                            <i className="fa-brands fa-x-twitter icon-data" />
                          </NavLink>
                        </li>
                        <li className="group-icon">
                          <NavLink
                            className="inside-group"
                            to="https://www.facebook.com/be.cerohero"
                            target="_blank"
                            aria-label="facebook social media"
                          >
                            <i className="fab fa-facebook-f icon-data" />
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li><NavLink className="image-all-data" to={"https://play.google.com/store/apps/details?id=com.cerohero"} target='_blank'>
                    <div className='footerqr-image-data footerqr-image-data1'>
                      <img className='qr-code' src='../images/landing/app-store-1.png' alt='no-data' />
                    </div>

                  </NavLink></li>
                  <li><NavLink className="image-all-data" to={"https://apps.apple.com/in/app/cero-hero/id6460891460"} target='_blank'>
                    <div className='footerqr-image-data footerqr-image-data1'>
                      <img className='qr-code' src='../images/landing/play-store-1.png' alt='no-data' />
                    </div>

                  </NavLink></li>

                </ul>

              </div>

            </div>

          </div>
          <ul className='row terms-and-conditions'>
            <li className='col-md-4'>
            <h4 className="terms-subtitle">Contact Info </h4>
                <p className="terms-para pb-0">E-mail: <a href="mailto:info@cerohero.com" class="contact-email">info@cerohero.com</a>  </p>

            </li>
            <li className='col-md-8'>
            <p className='copyrights-para'>CeroHero@2024  |  Created with Chakravuyha Software Technologies</p>

            </li>
          </ul>
         

        </div>

      </div>
    </>
  )
}

export default Footer